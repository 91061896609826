import Link from 'next/link';
import { NavigationMenuItem } from '@radix-ui/react-navigation-menu';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/shared/components/ui/accordion';
import { Button } from '@/shared/components/ui/button';

import { aboutNavigation, buttons, catalogNavigation, serviceNavigation } from './model';

export const HeaderMenuMobile = () => {
  return (
    <div>
      <Accordion type="multiple">
        <AccordionItem value="item-1" className="data-[state=open]:border-0">
          <AccordionTrigger className="text-xl">Меню</AccordionTrigger>
          <AccordionContent>
            <AccordionItem value="item-2">
              <AccordionTrigger className="text-xl">{aboutNavigation.title}</AccordionTrigger>
              {aboutNavigation.list.map((item, i) => {
                return (
                  <AccordionContent key={item.name + i}>
                    <Button asChild variant="ghost" className="p-2 text-xl">
                      <Link href={item.url}>{item.name}</Link>
                    </Button>
                  </AccordionContent>
                );
              })}
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger className="text-xl ">{catalogNavigation.title}</AccordionTrigger>
              {catalogNavigation.list.map((item, i) => {
                return (
                  <AccordionContent key={item.name + i}>
                    <Button asChild variant="ghost" className=" whitespace-normal p-2 text-xl">
                      <Link href={item.url}>{item.name}</Link>
                    </Button>
                  </AccordionContent>
                );
              })}
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger className="text-xl ">{serviceNavigation.title}</AccordionTrigger>
              {serviceNavigation.list.map((item, i) => {
                return (
                  <AccordionContent key={item.name + i}>
                    <Button asChild variant="ghost" className="p-2 text-xl">
                      <Link href={item.url}>{item.name}</Link>
                    </Button>
                  </AccordionContent>
                );
              })}
            </AccordionItem>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="mt-2 flex flex-col items-center">
        {buttons.map((item, i) => {
          return (
            <Button
              key={`${item} + i`}
              asChild
              variant="ghost"
              className="mr-auto whitespace-normal p-2 text-left text-xl"
            >
              <Link href={item.url}>{item.title}</Link>
            </Button>
          );
        })}
      </div>
    </div>
  );
};
