import { url } from 'inspector';
import { Url } from 'next/dist/shared/lib/router/router';

import { pagesPath } from '@/shared/config/$path';

interface HeaderNavigationList {
  name: string;
  url: Url;
}

interface HeaderNavigation {
  title: string;
  list: HeaderNavigationList[];
}

interface HeaderNavigationButtons {
  title: string;
  url: Url;
}

export const aboutNavigation: HeaderNavigation = {
  title: 'О компании',
  list: [
    { name: 'Проектирование решений', url: pagesPath.solutions.$url() },
    { name: 'Наши проекты', url: pagesPath.works.$url() },
    { name: 'Наши сертификаты', url: pagesPath.certificates.$url() },
    { name: 'Тендеры и участие в госзакупках. Импортозамещение', url: pagesPath.tenders.$url() },
  ],
};
export const catalogNavigation: HeaderNavigation = {
  title: 'Каталог',
  list: [
    { name: 'ИБП', url: pagesPath.catalog.$url() },
    { name: 'Энтел', url: pagesPath.catalog.$url() },
    { name: 'Вимп', url: pagesPath.catalog.$url() },
    { name: 'АКБ', url: pagesPath.catalog.$url() },
    { name: 'Коммуникации и ПО', url: pagesPath.catalog.$url() },
    { name: 'Опции', url: pagesPath.catalog.$url() },
  ],
};

export const serviceNavigation: HeaderNavigation = {
  title: 'Сервис',
  list: [
    { name: 'Ремонт ИБП', url: pagesPath.catalog.$url() },
    { name: 'Обслуживание ИБП', url: pagesPath.catalog.$url() },
    { name: 'Замена АКБ в ИБП', url: pagesPath.catalog.$url() },
  ],
};

export const buttons: HeaderNavigationButtons[] = [
  { title: 'Доставка', url: pagesPath.catalog.$url() },
  { title: 'Контакты', url: pagesPath.catalog.$url() },
];

export const ibpMenu: HeaderNavigation = {
  title: 'ИБП',
  list: [
    { name: 'ИБП для ЦОД и серверной', url: pagesPath.catalog.$url() },
    { name: 'Медицинские ИБП', url: pagesPath.catalog.$url() },
    { name: 'Промышленные ИБП', url: pagesPath.catalog.$url() },
  ],
};
