import Image from 'next/image';

import { Link } from '@/features/link';

import { pagesPath } from '@/shared/config/$path';

export const Footer = () => {
  return (
    <footer className="mt-auto ">
      <div className="text container mt-4 flex flex-col justify-between py-6 md:flex-row">
        <div className="flex flex-col items-center md:flex-row md:items-stretch">
          <div className="mr-4 ">
            <Image src="/images/logo.svg" width={46} height={15} className="w-[100px]" alt="Логотип"></Image>
          </div>
          <div className="flex flex-col">
            <span>© ООО «Центр бесперебойного электроснабжения»</span>
            <span>Все права защищены</span>
            <span>CBE LLC</span>
            <Link href={pagesPath.works.$url()}>Наши проекты</Link>
            <Link href="/">Для заказчиков</Link>
          </div>
        </div>
        <div className="mt-2 flex flex-col text-center md:mt-0 md:text-right">
          <div className="flex flex-col text-2xl">
            <Link href="tel:+7812247066">+7 (812) 247-06-60</Link>
            <Link href="tel:+78007079845">8-800-707-98-45</Link>
            <Link href="tel:+78007079845">8-800-707-98-45</Link>
          </div>
          <div className="mt-4 flex flex-col">
            <Link href="/">Политика конфиденциальности</Link>
            <Link href="/"> Информация об оферте</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
