import Image from 'next/image';
import Link from 'next/link';

import { ModalForSubscribe } from '@/features/modal-for-subscribe/modal-for-subscribe';

import { pagesPath } from '@/shared/config/$path';
import { Media, MediaContextProvider } from '@/shared/lib/media';

import { HeaderMenu } from './header-menu';
import { HeaderMenuMobile } from './header-menu-mobile';

export const Header = () => {
  return (
    <MediaContextProvider>
      <header className="container my-4">
        <div className="flex flex-col md:grid md:grid-cols-12 ">
          <div className="col-span-9 flex max-w-xl items-center text-center md:text-start">
            <Link href={pagesPath.$url()}>
              <Image alt="Логотип" src="/images/logo-full.svg" width={57} height={7} className="w-[500px]"></Image>
            </Link>
          </div>
          <div className="col-span-3 mt-4 flex flex-col items-center text-right md:mt-0 md:items-end">
            <a href="tel:+7812247066" className="text-link">
              +7 (812) 247-06-60
            </a>
            <a href="tel:+78007079845" className="text-link">
              8-800-707-98-45
            </a>
            <span className=" text-xs">Звонок по Росии бесплатный</span>
            <a className="my-3 text-link md:my-0" href="mailto:sales@cbe.com">
              sales@cbe.com
            </a>
            <div className="w-full md:ml-auto md:w-auto">
              <ModalForSubscribe title="Обратная связь" />
            </div>
          </div>
        </div>
        <Media greaterThanOrEqual="xl">
          <HeaderMenu />
        </Media>
        <Media lessThan="xl">
          <HeaderMenuMobile />
        </Media>
      </header>
    </MediaContextProvider>
  );
};
