import Link from 'next/link';

import { Button } from '@/shared/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/shared/components/ui/navigation-menu';

import { aboutNavigation, buttons, catalogNavigation, ibpMenu, serviceNavigation } from './model';

export const HeaderMenu = () => {
  return (
    <div className="mt-4">
      <NavigationMenu className="flex w-full max-w-full flex-col items-stretch">
        <NavigationMenuList className="flex w-full">
          <NavigationMenuItem className="flex-1">
            <NavigationMenuTrigger className="w-full p-2 text-xl">{aboutNavigation.title}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="flex flex-col p-4 md:w-[400px] lg:w-[950px]">
                {aboutNavigation.list.map((item, i) => {
                  return (
                    <NavigationMenuLink className="p-2 text-xl" key={item.name + i} asChild>
                      <Button asChild variant="ghost" className="justify-start whitespace-normal text-xl">
                        <Link href={item.url}>{item.name}</Link>
                      </Button>
                    </NavigationMenuLink>
                  );
                })}
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem className="flex-1">
            <NavigationMenuTrigger className="w-full p-2 text-xl">{catalogNavigation.title}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="flex flex-col p-4 md:w-[400px] lg:w-[950px]">
                {catalogNavigation.list.map((item, i) => {
                  return (
                    <NavigationMenuLink className="p-2 text-xl" key={item.name + i} asChild>
                      <Button asChild variant="ghost" className="justify-start whitespace-normal  text-xl">
                        <Link href={item.url}>{item.name}</Link>
                      </Button>
                    </NavigationMenuLink>
                  );
                })}
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem className="flex-1">
            <NavigationMenuTrigger className="w-full p-2 text-xl">{serviceNavigation.title}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="flex flex-col p-4 md:w-[400px] lg:w-[950px]">
                {serviceNavigation.list.map((item, i) => {
                  return (
                    <NavigationMenuLink className="p-2 text-xl" key={item.name + i} asChild>
                      <Button asChild variant="ghost" className="justify-start whitespace-normal text-xl">
                        <Link href={item.url}>{item.name}</Link>
                      </Button>
                    </NavigationMenuLink>
                  );
                })}
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem className="flex-1">
            <NavigationMenuTrigger className="w-full p-2 text-xl">{ibpMenu.title}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="flex flex-col p-4 md:w-[400px] lg:w-[950px]">
                {ibpMenu.list.map((item, i) => {
                  return (
                    <NavigationMenuLink className="p-2 text-xl" key={item.name + i} asChild>
                      <Button asChild variant="ghost" className="justify-start whitespace-normal text-xl">
                        <Link href={item.url}>{item.name}</Link>
                      </Button>
                    </NavigationMenuLink>
                  );
                })}
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>

          {buttons.map((item, i) => {
            return (
              <NavigationMenuItem key={`${item.title}`} className=" flex-1">
                <Button asChild variant="ghost" className="w-full whitespace-normal p-2 text-xl">
                  <Link href={item.url}>{item.title}</Link>
                </Button>
              </NavigationMenuItem>
            );
          })}
          <NavigationMenuIndicator />
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};
