import { ReactNode, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from '@/shared/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from '@/shared/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';
import { Toaster } from '@/shared/components/ui/toaster';
import { useToast } from '@/shared/components/ui/use-toast';

import { formSchema } from './model';

interface ModalForSubscribeProps {
  title: string | number;
  triggerSize?: 'default' | 'sm' | 'lg' | 'icon' | null | undefined;
  triggerVariant?: 'default' | 'link' | 'destructive' | 'outline' | 'secondary' | 'ghost' | null | undefined;
}

export const ModalForSubscribe = (props: ModalForSubscribeProps) => {
  const { title, triggerSize, triggerVariant } = props;
  const [dialogState, setDialogState] = useState<boolean>(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      email: '',
      phone: '',
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    toast({
      description: 'Ваша заявка отправлена!',
    });
    setDialogState(false);
  };

  return (
    <Dialog open={dialogState} onOpenChange={setDialogState}>
      <DialogTrigger asChild>
        <Button className="w-full" size={triggerSize} onClick={() => setDialogState(true)} variant={triggerVariant}>
          {title}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader className="text-2xl font-bold">Отправить заявку в ООО «ЦБЭ»</DialogHeader>
        <DialogDescription>
          Нажимая кнопку «Отправить», я подтверждаю, что я ознакомлен и согласен с условиями политики обработки
          персональных данных.
        </DialogDescription>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ваше Имя</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите ваше имя" type="text" autoComplete="name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ваш телефон</FormLabel>
                  <FormControl>
                    <Input placeholder="+79195067592" autoComplete="tel" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Petr_Kuznecov@mail.ru" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4 flex justify-end gap-3">
              <DialogClose asChild>
                <Button variant="destructive" type="button">
                  Отмена
                </Button>
              </DialogClose>
              <Button type="submit">Отправить</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
      <Toaster />
    </Dialog>
  );
};
