const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  "catalog": {
    _name: (name: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/catalog/[name]' as const, query: { name }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/catalog' as const, hash: url?.hash })
  },
  "certificates": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/certificates' as const, hash: url?.hash })
  },
  "solutions": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/solutions' as const, hash: url?.hash })
  },
  "tenders": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/tenders' as const, hash: url?.hash })
  },
  "works": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/works/[id]' as const, query: { id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/works' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  favicon_ico: '/favicon.ico',
  fonts: {
    Montserrat_Italic_ttf: '/fonts/Montserrat-Italic.ttf',
    Montserrat_Regular_ttf: '/fonts/Montserrat-Regular.ttf',
    Montserrat_SemiBold_ttf: '/fonts/Montserrat-SemiBold.ttf',
    good_timing_bd_ttf: '/fonts/good-timing-bd.ttf'
  },
  images: {
    bg_search_jpg: '/images/bg-search.jpg',
    logo_full_svg: '/images/logo-full.svg',
    logo_svg: '/images/logo.svg',
    product_image_png: '/images/product-image.png'
  },
  next_svg: '/next.svg',
  robots_txt: '/robots.txt',
  sitemap_0_xml: '/sitemap-0.xml',
  sitemap_xml: '/sitemap.xml',
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
