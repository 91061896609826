import { createEvent, createStore, sample } from 'effector';

import { GetAllProductsParams } from '@/shared/api/generated';
import { navigateToFx } from '@/shared/lib/effector/router';

export const $inputValue = createStore('');

export const onInputChange = createEvent<string>();

export const searchProduct = createEvent<GetAllProductsParams>();

sample({
  clock: searchProduct,
  fn: (request) => {
    const page = '/catalog';
    return { url: `${page}?search=${request.search}` };
  },
  target: navigateToFx,
});

sample({
  clock: onInputChange,
  target: $inputValue,
});

sample({
  clock: searchProduct,
  fn: () => '',
  target: $inputValue,
});
