import { useUnit } from 'effector-react';
import { RiSearchLine } from 'react-icons/ri';

import { Button } from '@/shared/components/ui/button';
import { Input } from '@/shared/components/ui/input';

import { $inputValue, onInputChange, searchProduct } from './model';

export const Search = () => {
  const [searchOnSubmit, inputValue, inputChange] = useUnit([searchProduct, $inputValue, onInputChange]);

  return (
    <div className="container">
      <div className="grid-cols-12 items-center gap-12 md:grid md:border md:p-9">
        <div className="hidden flex-col md:col-span-8 md:flex">
          <p className="text-2xl font-bold">
            Бесперебойное электропитание для промышленных предприятий и медицинских учреждений
          </p>
          <p className="pt-9 text-xl">
            Разработка индивидуальных проектов и поставка надежных решений для предприятий России и стран СНГ
          </p>
        </div>
        <div className="md:col-span-4 ">
          <form
            className="flex items-center space-x-2"
            onSubmit={(event) => {
              event.preventDefault();
              searchOnSubmit({ search: inputValue });
            }}
          >
            <Input
              placeholder="Поиск по сайту"
              type="search"
              value={inputValue}
              onChange={(event) => inputChange(event.target.value)}
            ></Input>
            <Button variant="ghost" type="submit" size="icon">
              <RiSearchLine className="text-primary" />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
