import { ReactNode } from 'react';
import { Url } from 'next/dist/shared/lib/router/router';
import NextLink from 'next/link';

interface LinkProps {
  href: Url;
  children: ReactNode;
}

export const Link = (props: LinkProps) => {
  const { children } = props;

  return (
    <NextLink className="text-link hover:underline hover:underline-offset-1" href={props.href}>
      {children}
    </NextLink>
  );
};
