import * as z from 'zod';

export const formSchema = z.object({
  username: z
    .string()
    .min(2, {
      message: 'Длинна имени должна быть больше 2х символов',
    })
    .max(50),
  email: z.string().email({ message: 'Некорректная почта' }),
  phone: z.string().min(1, { message: 'Это обязательное поле' }),
  // .regex(phoneRegex, 'Некорректный номер телефона'),
});
