import { ReactNode } from 'react';

import { Search } from '@/features/search';

import { Footer } from './footer';
import { Header } from './header';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  return (
    <>
      <Header />
      <Search />

      {children}
      <Footer />
    </>
  );
};
